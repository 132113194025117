import React, { useEffect, useState } from "react";
import * as XLSX from "xlsx";
import {
  Box,
  Button,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  Container,
  Grid,
  Stack,
  Divider,
  Alert,
  List,
  ListItem,
  ListItemText,
  CircularProgress,
} from "@mui/material";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import DownloadIcon from "@mui/icons-material/Download";
import { saveAs } from "file-saver";
import { LoadingButton } from "@mui/lab";
import { getHeader, toastError } from "../utils/util";
import axios from "axios";
import { toast } from "react-toastify";
import BackBtn from "../widgets/BackBtn";
import { ScrollTableContainer } from "../components/visitors/Visitors";
import {
  printAll,
  printRow,
} from "../dashboard/onsitemodule/ImtexBadgeDimension";
export const EXHIBITOR_TEMPLATE =
  "https://s3.ap-south-1.amazonaws.com/expoplanner.in/staff_list+copy.xlsx";
function PrintStaffBadges() {
  const [excelData, setExcelData] = useState([]);
  const [saving, setSaving] = useState(false);
  const [loading, setLoading] = useState(false); // Added loading state

  const handleFileUpload = (event) => {
    setLoading(true); // Start loading
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      const binaryStr = e.target.result;
      const workbook = XLSX.read(binaryStr, { type: "binary" });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];

      // Get all rows, including headers
      const jsonData = XLSX.utils.sheet_to_json(sheet, {
        header: 1, // Return as an array of arrays, including headers
        defval: "", // Default value for empty cells
      });

      if (jsonData.length > 0) {
        // Extract headers and map data rows
        const headers = jsonData[0];
        const dataRows = jsonData.slice(1);

        const formattedData = dataRows.map((row) => {
          const obj = {};
          headers.forEach((header, index) => {
            obj[header] = row[index] !== undefined ? row[index] : "";
          });

          return obj;
        });
        setExcelData(formattedData);
      } else {
        console.log("The uploaded file has no content.");
        setExcelData([]);
      }
      setLoading(false); // Stop loading
    };
    reader.onerror = () => {
      console.error("File could not be read!");
      setLoading(false); // Stop loading on error
    };
    reader.readAsBinaryString(file);
  };

  const saveList = async () => {};
  const onCancel = () => {
    document.location.reload();
  };
  return (
    <Box>
      <BackBtn />
      <Container>
        <Typography variant="h6" gutterBottom>
          Import Staff List from Excel File
        </Typography>
        <Grid container spacing={2}>
          <Grid item sm={6}>
            <Button
              startIcon={<ImportExportIcon />}
              variant="contained"
              component="label"
              sx={{ marginBottom: 2 }}
            >
              Import Excel File
              <input
                type="file"
                accept=".xlsx, .xls"
                onChange={handleFileUpload}
                hidden
              />
            </Button>
          </Grid>
          <Grid item sm={6}>
            <Button
              startIcon={<DownloadIcon />}
              variant="outlined"
              component="label"
              sx={{ marginBottom: 2 }}
              onClick={() => {
                saveAs(EXHIBITOR_TEMPLATE, "exhibitor_template.xlsx");
              }}
            >
              Download Excel Template
            </Button>
          </Grid>
        </Grid>

        {loading ? (
          // Show loading spinner
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="200px"
          >
            <CircularProgress />
          </Box>
        ) : (
          <>
            {excelData.length === 0 && (
              <Stack gap={2} direction="column">
                <Typography variant="h6" gutterBottom>
                  Some points to consider before uploading:
                </Typography>
                <List>
                  <ListItem>
                    <ListItemText primary="1. Your excel should contain the columns shown in the template." />
                  </ListItem>
                  <ListItem>
                    <ListItemText primary="2. At least one line entry should be there in the sheet." />
                  </ListItem>
                </List>
              </Stack>
            )}
            {excelData.length > 0 && (
              <>
                <Typography gutterBottom variant="body1">
                  Please review the following list before saving the list to the
                  database:
                </Typography>
                <Divider />
              </>
            )}
            <ScrollTableContainer>
              {excelData.length > 0 && (
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      {Object.keys(excelData[0]).map((key) => (
                        <TableCell key={key} sx={{ fontWeight: "bold" }}>
                          {key}
                        </TableCell>
                      ))}
                      <TableCell>Print</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {excelData.map((row, index) => (
                      <TableRow key={index}>
                        {Object.entries(row).map(([key, value], idx) => (
                          <TableCell key={idx}>
                            {value?.toLocaleString()}
                          </TableCell>
                        ))}
                        <TableCell>
                          <Button
                            onClick={() => {
                              const name = row["Name "];
                              const company = row["COMPANY NAME"];

                              printRow({
                                barcode_id: "imtma-orgainser",
                                firstName: name,
                                companyName: company,
                              });
                            }}
                            variant="outlined"
                            size="small"
                          >
                            Print
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              )}
            </ScrollTableContainer>

            {excelData.length > 0 && (
              <>
                <Typography mt={2} mb={2} variant="body1">
                  {/* If the contents in the list are correct, then you can proceed
                  to save the list: */}
                </Typography>
                <Stack direction={"row"} gap={2}>
                  <Button onClick={onCancel} variant="outlined">
                    Cancel
                  </Button>
                  <LoadingButton
                    onClick={() => {
                      if (excelData.length > 0) {
                        const printExcelData = excelData.map((row) => {
                          const name = row["Name "];
                          const company = row["COMPANY NAME"];
                          return {
                            barcode_id: "imtma-orgainser",
                            firstName: name,
                            companyName: company,
                          };
                        });
                        printAll(printExcelData);
                      }
                    }}
                    loading={saving}
                    variant="contained"
                  >
                    Print All
                  </LoadingButton>
                </Stack>
              </>
            )}
          </>
        )}
      </Container>
    </Box>
  );
}

export default PrintStaffBadges;
