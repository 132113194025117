import React, { useMemo } from "react";
import {
  Card,
  CardContent,
  Typography,
  Button,
  CircularProgress,
  Stack,
} from "@mui/material";
import {
  ResponsiveContainer,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  LabelList,
} from "recharts";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { API_ENDPOINT } from "../../utils/constants";
import useCommonGetInit from "../../hooks/useCommonGetInit";
import { exportToExcel } from "../../utils/util";

const MonthwiseRegistrationsCard = () => {
  const { data: monthwiseData, isLoading: isMonthwiseLoading } =
    useCommonGetInit(`${API_ENDPOINT}user/a/get-monthwise-registrations`);

  const dataByMonth = useMemo(() => {
    if (!monthwiseData) return [];

    // Only show data for months: 8,9,10,11,12,1 (regardless of year)
    const monthsToDisplay = [8, 9, 10, 11, 12, 1];

    // Create placeholder data
    // We'll label them "Aug", "Sep", "Oct", "Nov", "Dec", "Jan"
    // But be aware that "Jan" might come from any year in the data
    const initialData = [
      { month: "Aug", visitors: 0, numericMonth: 8 },
      { month: "Sep", visitors: 0, numericMonth: 9 },
      { month: "Oct", visitors: 0, numericMonth: 10 },
      { month: "Nov", visitors: 0, numericMonth: 11 },
      { month: "Dec", visitors: 0, numericMonth: 12 },
      { month: "Jan", visitors: 0, numericMonth: 1 },
    ];

    monthwiseData.registrations.forEach((item) => {
      // If item.month is a string, parseInt it
      const itemMonth = Number(item.month);

      // Check if itemMonth is one of our desired months
      const idx = initialData.findIndex((m) => m.numericMonth === itemMonth);
      if (idx !== -1) {
        initialData[idx].visitors += item.count;
        // ^ use += in case your API returns multiple years or multiple records for the same month
      }
    });

    return initialData;
  }, [monthwiseData]);

  if (isMonthwiseLoading) {
    return (
      <Card>
        <CardContent>
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            height={250}
          >
            <CircularProgress />
          </Stack>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card>
      <CardContent>
        <Stack direction="row" spacing={2} mb={1}>
          <Typography flex={1} variant="h6">
            Registrations by Month
          </Typography>
          <Button
            size="small"
            variant="outlined"
            startIcon={<FileDownloadIcon />}
            onClick={() =>
              exportToExcel(
                dataByMonth,
                "Registrations_by_Month_Aug-Dec_and_Jan"
              )
            }
          >
            Export
          </Button>
        </Stack>
        <ResponsiveContainer width="100%" height={250}>
          <BarChart data={dataByMonth}>
            <XAxis dataKey="month" />
            <YAxis domain={[0, (dataMax) => Math.ceil(dataMax * 1.2)]} />
            <Tooltip />
            <Legend />
            <Bar dataKey="visitors" fill="#8884d8">
              <LabelList dataKey="visitors" position="top" />
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </CardContent>
    </Card>
  );
};

export default MonthwiseRegistrationsCard;
