import React, { useEffect, useState } from "react";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  TextField,
  Typography,
  IconButton,
  Stack,
  Link as MuiLink,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useNavigate, useParams } from "react-router-dom";
import { API_ENDPOINT } from "../../utils/constants";
import useCommonGetInit from "../../hooks/useCommonGetInit";
import { isOnsiteActivated } from "./utils";
import MuPb from "../../widgets/MuPb";
import { toast } from "react-toastify";

const LocationWiseScanLink = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const {
    data: Mdata,
    isLoading,
    isError,
    error,
  } = useCommonGetInit(`${API_ENDPOINT}user/check-onsite?id=${id}`);
  const [locName, setLocName] = useState("");
  const [generatedLink, setGeneratedLink] = useState("");

  useEffect(() => {
    if (Mdata) {
      if (!isOnsiteActivated(Mdata.module)) {
        navigate(-1);
      }
    }
  }, [Mdata]);

  if (isLoading) {
    return <MuPb />;
  }
  if (isError) {
    return <Alert severity="error">{error?.message}</Alert>;
  }

  const generateLoc = () => {
    if (!locName.trim()) {
      alert("Please enter a valid location name.");
      return;
    }
    const randomCode = Math.random().toString(36).substring(2, 8).toUpperCase();
    const encodedLocName = encodeURIComponent(locName.trim());
    const link = `/onsite/gate-scan/${encodedLocName}/${id}`;
    setGeneratedLink(link);
    toast.success("Link generated successfully");
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(`${window.location.origin}${generatedLink}`);
    toast("Link copied to clipboard!");
  };

  return (
    <Box>
      <Typography mt={2} variant="h5" textAlign={"center"}>
        Location-Wise Security Scan
      </Typography>
      <Alert
        severity="info"
        icon={<InfoIcon fontSize="large" />}
        sx={{ alignItems: "flex-start" }}
      >
        <AlertTitle>
          <Typography
            variant="h6"
            sx={{ fontWeight: "bold", color: "#0288d1" }}
          >
            Important Instructions
          </Typography>
        </AlertTitle>
        <Typography variant="body1">
          When you click on "Submit," a link will be generated and displayed.
          You can generate multiple links, but you must ensure:
        </Typography>
        <ul style={{ margin: 0, paddingLeft: "1.5rem" }}>
          <li>
            <Typography variant="body1">
              The location name you enter reflects the actual location as it
              will appear in the location-wise footfall analysis.
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              The footfall data from scans will be associated with the entered
              location name.
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              Ensure you use only the link associated with the correctly entered
              location name.
            </Typography>
          </li>
        </ul>
        <Typography variant="body1" sx={{ marginTop: 1, fontWeight: "bold" }}>
          Enter the location name carefully, as this directly impacts the
          accuracy of the analysis.
        </Typography>
      </Alert>
      <Box
        mt={4}
        sx={{
          maxWidth: 600,
          ml: "auto",
          mr: "auto",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          flexGrow: 1,
        }}
      >
        <TextField
          fullWidth
          size="small"
          sx={{ mt: 2 }}
          label="Location Name"
          variant="outlined"
          value={locName}
          onChange={(e) => setLocName(e.target.value)}
        />

        <Button
          size="small"
          fullWidth
          sx={{ mt: 2 }}
          variant="contained"
          color="primary"
          onClick={generateLoc}
        >
          Submit
        </Button>

        {generatedLink && (
          <Alert severity="success" sx={{ mt: 3 }}>
            <Stack direction="row" alignItems="center" spacing={1}>
              <Typography variant="body1" sx={{ flexGrow: 1 }}>
                Link generated successfully! Use the link below:
              </Typography>
              <IconButton
                color="primary"
                size="small"
                onClick={copyToClipboard}
              >
                <ContentCopyIcon />
              </IconButton>
            </Stack>
            <MuiLink
              href={`${window.location.origin}${generatedLink}`}
              target="_blank"
              rel="noopener noreferrer"
              sx={{
                mt: 1,
                wordWrap: "break-word",
                fontWeight: "bold",
                color: "#1976d2",
                textDecoration: "none",
                "&:hover": {
                  textDecoration: "underline",
                },
              }}
            >
              {`${window.location.origin}${generatedLink}`.length > 60
                ? `${window.location.origin}${generatedLink}`.substring(0, 58) +
                  "..."
                : `${window.location.origin}${generatedLink}`}
            </MuiLink>
          </Alert>
        )}
      </Box>
    </Box>
  );
};

export default LocationWiseScanLink;
