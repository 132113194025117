import React, { useEffect, useState } from "react";
import {
  Container,
  Typography,
  List,
  ListItem,
  ListItemText,
  CircularProgress,
  Box,
} from "@mui/material";

export default function IncompleteExhibitors() {
  const [data, setData] = useState([]);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Fetch the list of incomplete exhibitors
    fetch("https://apis.expoplanner.in/user/get-exhibitors-incomplete")
      .then((response) => response.json())
      .then((res) => {
        setData(res.data);
        setCount(res.count);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching exhibitors:", error);
        setLoading(false);
      });
  }, []);

  if (loading) {
    return (
      <Box display="flex" alignItems="center" justifyContent="center" mt={4}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container maxWidth="md" sx={{ mt: 4 }}>
      <Typography variant="h5" gutterBottom>
        Exhibitors With Less Than 70% Profile Completion
      </Typography>

      <Typography variant="subtitle1" gutterBottom>
        Number of Incomplete Exhibitors: <strong>{count}</strong>
      </Typography>

      <List>
        {data.map((exhibitor) => (
          <ListItem key={exhibitor._id} divider>
            <ListItemText
              primary={exhibitor.username}
              secondary={
                exhibitor.companyName?.value || "No Company Name Provided"
              }
            />
          </ListItem>
        ))}
      </List>
    </Container>
  );
}
