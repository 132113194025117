import { Box, Container } from "@mui/material";
import React from "react";
import OnsiteFooter from "./OnsiteFooter";
import OnsiteHeader from "./OnsiteHeader";
import { Route, Routes } from "react-router-dom";
import SpotRegistration from "./SpotRegistration";
import SecurityScan from "./SecurityScan";
import OnsiteReuseContainer from "./OnsiteReuseContainer";
import SelfPrinting from "./SelfPrinting";
import BulkPrinting from "./BulkPrinting";
import SearchPrint from "./SearchPrint";
import ServiceBadge from "./service/ServiceBadge";
import BulkStudent from "./BulkStudent";
import FootfallReport from "./FootfallReport";
import DaywiseFootfall from "./DaywiseFootfall";
import DayWiseFootfallReport from "./DayWiseFootfallReport";
import LocationWiseScanLink from "./LocationWiseScanLink";
import GatwiseScan from "./GatwiseScan";

const OnsiteContainer = () => {
  return (
    <OnsiteReuseContainer>
      <Routes>
        <Route path="spot-registration/:id" element={<SpotRegistration />} />
        <Route path="security-scan/:id" element={<SecurityScan />} />
        <Route path="self-printing/:id" element={<SelfPrinting />} />
        <Route path="bulk-printing/:id" element={<BulkPrinting />} />
        <Route path="search-print/:id" element={<SearchPrint />} />
        <Route path="service-badge" element={<ServiceBadge />} />
        <Route
          path="locationwise-scan-link-create/:id"
          element={<LocationWiseScanLink />}
        />
        <Route path="gate-scan/:gid/:id" element={<GatwiseScan />} />

        <Route
          path="daywise-footfall/:id"
          element={<DayWiseFootfallReport />}
        />
        <Route path="bulk-student-badges/:id" element={<BulkStudent />} />
        <Route path="footfall-report/:id" element={<DaywiseFootfall />} />
        {/* <Route path="daywise-footfall-report" element={<DaywiseFootfall />} />
         */}
      </Routes>
    </OnsiteReuseContainer>
  );
};

export default OnsiteContainer;
