import React, { useCallback, useState } from "react";
import {
  Box,
  Button,
  Grid,
  Pagination,
  Stack,
  Typography,
  Paper,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import PrintIcon from "@mui/icons-material/Print";

import useCommonGetInit from "../../hooks/useCommonGetInit";
import { API_ENDPOINT } from "../../utils/constants";
import BackBtn from "../../widgets/BackBtn";
import CustomCircularP from "../../widgets/CustomCircularP";
import SearchBar from "../../widgets/searchBar";
import { ScrollTableContainer } from "../../components/visitors/Visitors";
import { useNavigate } from "react-router-dom";
import { printAll, printRow } from "../onsitemodule/ImtexBadgeDimension";

const DelegatesList = () => {
  const [reload, setReload] = useState(false);
  const [page, setPage] = useState(1);
  const [sq, setSq] = useState("");
  const navigate = useNavigate();

  // Track selected rows
  const [selected, setSelected] = useState([]);

  const { data, isLoading, isError, error } = useCommonGetInit(
    `${API_ENDPOINT}admin/get-delegates-list?page=${page}&sq=${sq}&r=${reload}`
  );

  const debounce = (func, delay) => {
    let timeoutId;
    return (...args) => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        func.apply(null, args);
      }, delay);
    };
  };

  const handleSearch = (e) => {
    const value = e.target.value;
    setSq(value);
  };
  const debouncedHandleSearch = useCallback(debounce(handleSearch, 500), []);

  const pageChange = (event, newPage) => {
    setPage(newPage);
  };

  /**
   * Returns true if the specific user is currently selected.
   * (Assuming each user has a unique `_id` or `id`—adjust as necessary.)
   */
  const isRowSelected = (user) => selected.some((sel) => sel._id === user._id);

  /**
   * Toggle selection for a single row.
   */
  const handleRowSelect = (user) => {
    if (isRowSelected(user)) {
      // remove from selected
      setSelected(selected.filter((sel) => sel._id !== user._id));
    } else {
      // add to selected
      setSelected([...selected, user]);
    }
  };

  /**
   * Determine if all rows on the current page are selected
   */
  const areAllSelected = () =>
    data?.data?.length > 0 && data.data.every((user) => isRowSelected(user));

  /**
   * Toggle "select all" for the entire current page.
   */
  const handleSelectAll = () => {
    if (areAllSelected()) {
      // If all are already selected, unselect them
      const allIds = data.data.map((user) => user._id);
      setSelected(selected.filter((sel) => !allIds.includes(sel._id)));
    } else {
      // Otherwise, merge the current data with already-selected items
      // Make sure we don't add duplicates
      const newSelections = [...selected];
      data.data.forEach((user) => {
        if (!isRowSelected(user)) {
          newSelections.push(user);
        }
      });
      setSelected(newSelections);
    }
  };

  /**
   * Handle Print All for only selected rows
   */
  const handlePrintAllSelected = () => {
    // Pass the selected list to printAll
    printAll(selected);
  };

  return (
    <Box>
      <BackBtn />
      <CustomCircularP show={isLoading} />
      <Grid container mb={2} rowSpacing={2}>
        <Grid item md={2} alignContent="center">
          <Stack direction="row" gap={2}>
            <Typography variant="h5">Delegates</Typography>
          </Stack>
        </Grid>
        <Grid item md={6}>
          <SearchBar
            placeholder="Search by name/email"
            size="small"
            onChange={debouncedHandleSearch}
          />
        </Grid>
        <Grid item md={4} alignContent="center">
          <Stack ml={1} gap={1} direction="row">
            <Typography alignSelf="center" variant="body1">
              Selected: {selected.length}
            </Typography>
            <Button
              onClick={handlePrintAllSelected}
              disabled={selected.length === 0}
              variant="contained"
              startIcon={<PrintIcon />}
            >
              Print All
            </Button>
            <Button
              variant="outlined"
              size="small"
              onClick={() => {
                navigate("/home/add-trade-delegate-excel");
              }}
            >
              Add List
            </Button>
          </Stack>
        </Grid>
      </Grid>

      <Box>
        <ScrollTableContainer component={Paper}>
          <Table stickyHeader aria-label="scrollable table">
            <TableHead>
              <TableRow>
                {/* "Select All" Header Checkbox */}
                <TableCell padding="checkbox">
                  <Checkbox
                    color="primary"
                    indeterminate={
                      selected.length > 0 &&
                      selected.length < data?.data?.length
                    }
                    checked={areAllSelected()}
                    onChange={handleSelectAll}
                  />
                </TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Designation</TableCell>
                <TableCell>Company</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Phone</TableCell>
                <TableCell>Country</TableCell>
                <TableCell>Address</TableCell>
                <TableCell>Print</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.data?.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={9} align="center">
                    No data available.
                  </TableCell>
                </TableRow>
              ) : (
                data?.data?.map((user, index) => {
                  const selectedRow = isRowSelected(user);
                  return (
                    <TableRow
                      key={index}
                      hover
                      selected={selectedRow}
                      onClick={() => handleRowSelect(user)}
                      style={{ cursor: "pointer" }}
                    >
                      {/* Individual Row Checkbox */}
                      <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          checked={selectedRow}
                          onChange={() => handleRowSelect(user)}
                          onClick={(e) => e.stopPropagation()} // Prevent double toggling
                        />
                      </TableCell>
                      <TableCell>
                        {user.title} {user.firstName} {user.lastName}
                      </TableCell>
                      <TableCell>{user.designation}</TableCell>
                      <TableCell>{user.companyName}</TableCell>
                      <TableCell>{user.email}</TableCell>
                      <TableCell>{user.mobileNo}</TableCell>
                      <TableCell>{user.country?.name}</TableCell>
                      <TableCell>{user.address}</TableCell>
                      <TableCell>
                        <Button
                          startIcon={<PrintIcon />}
                          variant="outlined"
                          size="small"
                          onClick={(e) => {
                            e.stopPropagation(); // Don't trigger row select
                            printRow(user);
                          }}
                        >
                          Print
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })
              )}
            </TableBody>
          </Table>
        </ScrollTableContainer>
      </Box>

      <Box display="flex" justifyContent="center" mt={2} mb={2}>
        {data && (
          <Pagination
            page={page}
            onChange={pageChange}
            count={Math.ceil(data.totalItems / 30)}
          />
        )}
      </Box>
    </Box>
  );
};

export default DelegatesList;
