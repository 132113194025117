import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputBase,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { IconButton } from "@mui/material";
import PrintIcon from "@mui/icons-material/Print";
import SearchIcon from "@mui/icons-material/Search";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Avatar,
} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import { printAll, printRow } from "./ImtexBadgeDimension";
import { toastError } from "../../utils/util";
import { getOnsiteHeader, isOnsiteActivated } from "./utils";
import CustomCircularP from "../../widgets/CustomCircularP";
import { API_ENDPOINT } from "../../utils/constants";
import useCommonGetInit from "../../hooks/useCommonGetInit";
import MuPb from "../../widgets/MuPb";
const BulkPrinting = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const {
    data: Mdata,
    isLoading,
    isError,
    error,
  } = useCommonGetInit(`${API_ENDPOINT}user/check-onsite?id=${id}`);

  // States
  const [loading, setLoading] = useState(false);
  const [exhibitorId, setExhibitorId] = useState("");
  const [halls, setHalls] = useState([]);
  const [hallNumber, setHallNumber] = useState("");
  const [exhibitors, setExhibitors] = useState([]);
  const [teamMs, setTeamMs] = useState([]);
  const [loadingExhibitors, setLoadingExhibitors] = useState(false);
  const [selected, setSelected] = useState([]);

  // For pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  // For search
  // We'll keep two states: one for the raw input `searchValue`,
  // another for the "debounced" or "throttled" `debouncedSearch`.
  const [searchValue, setSearchValue] = useState("");
  const [debouncedSearch, setDebouncedSearch] = useState("");

  // -----------------------------------------------------------
  // 1) On mount, check Mdata, load halls, etc.
  // -----------------------------------------------------------
  useEffect(() => {
    if (Mdata) {
      if (!isOnsiteActivated(Mdata.module)) {
        navigate(-1);
      }
      loadOnsiteHalls();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Mdata]);

  // -----------------------------------------------------------
  // 2) Debounce (or throttle) the `searchValue` to `debouncedSearch`
  // -----------------------------------------------------------
  useEffect(() => {
    // Start a timer to update `debouncedSearch` after 500ms
    const timer = setTimeout(() => {
      setDebouncedSearch(searchValue);
    }, 500);

    // Clear the timer if the user is still typing
    return () => clearTimeout(timer);
  }, [searchValue]);

  // -----------------------------------------------------------
  // 3) When hallNumber or exhibitorId or debouncedSearch changes,
  //    or pagination changes, load the team members
  //    (only if hall and exhibitor are selected).
  // -----------------------------------------------------------
  useEffect(() => {
    if (hallNumber) {
      loadTmsHall();
    } else {
      setTeamMs([]);
      setTotalPages(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hallNumber, exhibitorId, debouncedSearch, currentPage]);

  // -----------------------------------------------------------
  // 4) Load halls
  // -----------------------------------------------------------
  async function loadOnsiteHalls() {
    try {
      const { data } = await axios.get(
        `${API_ENDPOINT}user/get-onsite-halls`,
        getOnsiteHeader(Mdata.module)
      );
      setHalls(data);
    } catch (error) {
      toastError(error);
    }
  }

  // -----------------------------------------------------------
  // 5) Load exhibitors for selected hall
  //    (only triggered when user selects a new hall)
  // -----------------------------------------------------------
  async function loadExhibitors(hallN) {
    setLoading(true);
    try {
      const { data } = await axios.get(
        `${API_ENDPOINT}user/get-onsite-exhibitors?hallId=${hallN}`,
        getOnsiteHeader(Mdata.module)
      );
      setExhibitors(data);
    } catch (error) {
      toastError(error);
    } finally {
      setLoading(false);
    }
  }

  // -----------------------------------------------------------
  // 6) Load Team Members based on hall, exhibitor, search, page
  // -----------------------------------------------------------
  async function loadTmsHall() {
    setLoadingExhibitors(true);
    try {
      const response = await axios.get(
        `${API_ENDPOINT}user/get-team-members-by-hall?hallName=${hallNumber}&exhibitorId=${exhibitorId}&sq=${debouncedSearch}&page=${currentPage}`,
        getOnsiteHeader(Mdata.module)
      );
      const { items, currentPage: cp, totalPages } = response.data;
      setTeamMs(items);
      setCurrentPage(cp);
      setTotalPages(totalPages);
    } catch (error) {
      toastError(error);
    } finally {
      setLoadingExhibitors(false);
    }
  }

  // -----------------------------------------------------------
  // 7) Handlers
  // -----------------------------------------------------------
  const handleHallChange = (e) => {
    const hall = e.target.value;
    setHallNumber(hall);

    // Reset exhibitor when hall changes
    setExhibitorId("");

    // Reset page
    setCurrentPage(1);

    // Load exhibitors for this hall
    if (hall) {
      loadExhibitors(hall);
    } else {
      setExhibitors([]);
    }
  };

  const handleExhibitorChange = (event, newValue) => {
    setExhibitorId(newValue ? newValue.username : "");
    setCurrentPage(1);
  };

  const handleSearchInputChange = (event) => {
    setSearchValue(event.target.value);
    // Also reset page to 1 on new search
    setCurrentPage(1);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = teamMs.map((n) => n._id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const handleRowSelect = (item) => {
    setSelected((prevSelected) => {
      if (prevSelected.includes(item._id)) {
        return prevSelected.filter((id) => id !== item._id);
      } else {
        return [...prevSelected, item._id];
      }
    });
  };

  const handlePrintAll = () => {
    const myArr = [];
    for (const id of selected) {
      const myObj = teamMs.find((item) => item._id === id);
      if (myObj) {
        myArr.push({
          firstName: myObj.firstName,
          lastName: myObj.lastName,
          companyName: myObj.companyName.value,
          barcode_id: myObj._id,
        });
      }
    }
    printAll(myArr);
  };

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  // -----------------------------------------------------------
  // 8) Conditional Renders for loading/error states
  // -----------------------------------------------------------
  if (isLoading) {
    return <MuPb />;
  }
  if (isError) {
    return <Alert severity="error">{error?.message}</Alert>;
  }

  // -----------------------------------------------------------
  // 9) Render the UI
  // -----------------------------------------------------------
  return (
    <Box>
      <CustomCircularP show={loadingExhibitors} />
      <Box
        mt={1}
        width={"100%"}
        maxWidth={700}
        pl={1}
        pr={1}
        ml={"auto"}
        mr={"auto"}
      >
        <Box mt={2} width={"100%"} display={"flex"} gap={2}>
          {/* HALL SELECT */}
          <FormControl size="small" fullWidth>
            <InputLabel>Hall Number</InputLabel>
            <Select
              value={hallNumber}
              label="Select Hall Number"
              onChange={handleHallChange}
            >
              {halls.map((hall) => (
                <MenuItem key={hall.name} value={hall.name}>
                  {hall.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* EXHIBITOR AUTOCOMPLETE */}
          <Autocomplete
            size="small"
            fullWidth
            options={exhibitors}
            getOptionLabel={(option) => option.companyName.value}
            onChange={handleExhibitorChange}
            value={
              // Ensure that Autocomplete is controlled properly
              exhibitors.find((ex) => ex.username === exhibitorId) || null
            }
            renderInput={(params) => (
              <TextField {...params} label="Select Exhibitor" />
            )}
          />
        </Box>

        {/* SEARCH BOX - only enabled if both hall + exhibitor selected */}
        <Paper
          sx={{
            mt: 2,
            padding: "2px 4px 2px 10px",
            backgroundColor: "#f4f4f4",
            display: "flex",
            alignItems: "center",
            opacity: hallNumber && exhibitorId ? 1 : 0.5,
            pointerEvents: hallNumber && exhibitorId ? "auto" : "none",
          }}
        >
          <InputBase
            sx={{ ml: 1, flex: 1 }}
            placeholder="Search Members by Name"
            value={searchValue}
            onChange={handleSearchInputChange}
            inputProps={{
              "aria-label": "Search Members by Name",
            }}
          />
          <IconButton type="submit" sx={{ p: "8px" }} aria-label="search">
            <SearchIcon />
          </IconButton>
        </Paper>
      </Box>

      {/* TABLE / PAGINATION */}
      <Box p={3} maxWidth={1280} ml={"auto"} mr={"auto"} width={"100%"}>
        <Box display={"flex"}>
          <Box flex={1}>
            <Typography fontWeight={"bold"}>
              Selected: {selected.length}
            </Typography>
          </Box>
          <Button
            onClick={handlePrintAll}
            disabled={selected.length === 0}
            variant="contained"
            startIcon={<PrintIcon />}
          >
            Print All
          </Button>
        </Box>

        <TableContainer component={Paper} sx={{ mt: 2 }}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <FormControlLabel
                    control={
                      <Checkbox
                        indeterminate={
                          selected.length > 0 && selected.length < teamMs.length
                        }
                        checked={
                          teamMs.length > 0 && selected.length === teamMs.length
                        }
                        onChange={handleSelectAllClick}
                      />
                    }
                    label="Select All"
                  />
                </TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Profile Image</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Name</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Company Name</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Phone</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Email</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Job Title</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Print</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {teamMs.map((row, i) => {
                const isItemSelected = isSelected(row._id);
                return (
                  <TableRow key={row._id || i}>
                    <TableCell padding="checkbox">
                      <Checkbox
                        onChange={() => handleRowSelect(row)}
                        checked={isItemSelected}
                      />
                    </TableCell>
                    <TableCell>
                      {row.profile_image ? (
                        <Avatar
                          alt={`${row.firstName} ${row.lastName}`}
                          src={row.profile_image}
                        />
                      ) : (
                        <PersonIcon />
                      )}
                    </TableCell>
                    <TableCell>
                      {row.firstName} {row.lastName}
                    </TableCell>
                    <TableCell>{row.companyName?.value}</TableCell>
                    <TableCell>{row.phone}</TableCell>
                    <TableCell>{row.email}</TableCell>
                    <TableCell>{row.designation}</TableCell>
                    <TableCell>
                      <Button
                        variant="contained"
                        sx={{
                          textTransform: "none",
                          width: "96px",
                          bgcolor: row.print_count > 0 ? "red" : "primary",
                        }}
                        startIcon={<PrintIcon />}
                        size="small"
                        onClick={() => {
                          printRow({
                            firstName: row.firstName,
                            lastName: row.lastName,
                            companyName: row.companyName?.value,
                            barcode_id: row._id,
                          });
                        }}
                      >
                        Print {row.print_count}
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>

        {/* Pagination Control */}
        {teamMs.length > 0 && (
          <Box display="flex" justifyContent="center" mt={2}>
            <Pagination
              page={currentPage}
              count={totalPages}
              onChange={handleChangePage}
              color="primary"
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default BulkPrinting;
