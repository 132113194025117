import { Fragment } from "react";
import "./App.css";
import "react-quill/dist/quill.snow.css";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import EmailBuilder from "./EmailBuilder";
import MyQuillEditor from "./quill/MyQuillEditor";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CategoryBuilder from "./CategoryBuilder";
import SwipeableTemporaryDrawer from "./Demo";
import MiniDrawer from "./SlideHeader";
import DragDroppable from "./DragDroppable";
import ExportSymposium from "./exportSymposium";
import SecurityScan from "./symposium/SecurityScan";
import FormBuilder from "./formbuilder/FormBuilder";
import RegistrationContainer from "./formbuilder/RegistrationContainer";
import ChooseOption from "./formbuilder/ChooseOption";
import MultiSelectDropdown from "./formbuilder/MultiSelectDropdown";
import MyDatePicker from "./formbuilder/DatePicker";
import Login from "./login/Login";
import Logout from "./login/Logout";
import { UserAuth } from "./utils/util";
import { ADMIN } from "./utils/constants";
import { QueryClient, QueryClientProvider } from "react-query";
import OesAddItems from "./formbuilder/oesform/OesAddItems";
import { ThemeProvider } from "@mui/material";
import { ExpoTheme } from "./themes/ExpoTheme";
import MainDash from "./dashboard/MainDash";
import { UserProvider } from "./context/UserContext";
import PropertyListPopover from "./formbuilder/oesform/Proplist";
import SeminarHalls from "./formbuilder/oesform/seminarhalls/SeminarHalls";
import OnsiteContainer from "./dashboard/onsitemodule/OnsiteContainer";
import SharableOnsite from "./dashboard/onsitemodule/SharableOnsite";
import { ExportProvider } from "./context/ExportContext";
import EventManage from "./pages/EventManage";
import IncompleteExhibitors from "./pages/IncompleteExhibitors";
const queryClient = new QueryClient();
function App() {
  return (
    <ThemeProvider theme={ExpoTheme}>
      <QueryClientProvider client={queryClient}>
        <Fragment>
          <BrowserRouter>
            <ToastContainer autoClose={1500} />
            <Routes>
              <Route path="/" element={<Navigate to="/home" replace />} />
              <Route
                path="/home/*"
                element={
                  <UserProvider>
                    <UserAuth type={ADMIN}>
                      <ExportProvider>
                        <MainDash />
                      </ExportProvider>
                    </UserAuth>
                  </UserProvider>
                }
              />
              <Route path="/email" element={<EmailBuilder />} />
              <Route path="/login" element={<Login />} />

              <Route path="/logout" element={<Logout />} />
              <Route
                path="/admin"
                element={
                  <UserAuth type={ADMIN}>
                    <ChooseOption />
                  </UserAuth>
                }
              />

              <Route
                path="/reg-form-builder"
                element={
                  <UserAuth type={ADMIN}>
                    <FormBuilder />
                  </UserAuth>
                }
              />
              <Route
                path="/incomplete-exhibitors-list"
                element={<IncompleteExhibitors />}
              />

              <Route path="/prop-list" element={<PropertyListPopover />} />
              <Route path="/seminar-halls" element={<SeminarHalls />} />
              <Route path="/oes-add-items" element={<OesAddItems />} />
              <Route path="/multi-sel" element={<MultiSelectDropdown />} />
              <Route path="/date-picker" element={<MyDatePicker />} />
              <Route path="/test-dnd" element={<DragDroppable />} />
              {/* <Route path="/choose-form-type" element={<ChooseOption />} /> */}

              <Route
                path="/export-symposium-users"
                element={<ExportSymposium />}
              />
              <Route
                path="/symposium-security-scan"
                element={<SecurityScan />}
              />
              <Route path="/demo" element={<SwipeableTemporaryDrawer />} />
              <Route path="/reg-view" element={<RegistrationContainer />} />
              <Route path="/slide-draw" element={<MiniDrawer />} />
              <Route path="/category-builder" element={<CategoryBuilder />} />
              <Route
                path="/email-template-builder"
                element={<MyQuillEditor />}
              />
              <Route path="/manage-home" element={<EventManage />} />
              <Route path="/onsite/*" element={<OnsiteContainer />} />
              <Route path="/onsite-module/:id" element={<SharableOnsite />} />
            </Routes>
          </BrowserRouter>
        </Fragment>
      </QueryClientProvider>
    </ThemeProvider>
  );
}

export default App;
