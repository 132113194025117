import React from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Container,
  Box,
  Grid,
  Card,
  CardContent,
  Stack,
} from "@mui/material";
import { Event, Email, QrCode, Timeline } from "@mui/icons-material";

export default function EventManage() {
  return (
    <Box>
      {/* Top Navigation Bar */}
      <AppBar position="static" elevation={0}>
        <Toolbar sx={{ px: 2 }}>
          <Typography variant="h6" sx={{ flexGrow: 1, fontWeight: "bold" }}>
            EventMaster
          </Typography>
          <Button
            variant="text"
            sx={{
              color: "#fff",
              mr: 2,
              textTransform: "none",
              ":hover": { bgcolor: "rgba(255,255,255,0.1)" },
            }}
          >
            Login
          </Button>
          <Button
            variant="outlined"
            sx={{
              color: "#fff",
              borderColor: "#fff",
              textTransform: "none",
              ":hover": {
                borderColor: "#fff",
                bgcolor: "rgba(255,255,255,0.1)",
              },
            }}
          >
            Sign Up
          </Button>
        </Toolbar>
      </AppBar>

      {/* Hero Section */}
      <Box
        sx={{
          minHeight: "60vh",
          display: "flex",
          alignItems: "center",
          color: "#fff",
          p: 4,
          background: (theme) =>
            `linear-gradient(to right, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
        }}
      >
        <Container>
          <Grid container spacing={4} alignItems="center">
            <Grid item xs={12} md={6}>
              <Typography
                variant="h3"
                component="h1"
                gutterBottom
                sx={{ fontWeight: "bold" }}
              >
                Simplify Your Event Management
              </Typography>
              <Typography variant="h6" component="p" gutterBottom>
                Create custom registration pages, send automated confirmations,
                and track event attendance in real-time with QR codes.
              </Typography>
              <Button
                variant="contained"
                size="large"
                sx={{
                  mt: 3,
                  background: "linear-gradient(to right, #02475f, #f50057)",
                  color: "#fff",
                  borderRadius: "8px",
                  px: 4,
                  py: 1.5,
                  fontWeight: "bold",
                  textTransform: "none",
                  boxShadow: "0 4px 10px rgba(0,0,0,0.2)",
                  transition: "background 0.3s ease",
                  ":hover": {
                    background: "linear-gradient(to right, #023546, #d4004f)",
                    boxShadow: "0 6px 14px rgba(0,0,0,0.3)",
                  },
                  ":active": {
                    boxShadow: "0 3px 6px rgba(0,0,0,0.2)",
                  },
                }}
              >
                Get Started
              </Button>
            </Grid>
            <Grid item xs={12} md={6}>
              {/* Illustration or Image Placeholder */}
              <Box
                sx={{
                  backgroundColor: "#fff",
                  borderRadius: 2,
                  height: 300,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography variant="h6" sx={{ color: "text.secondary" }}>
                  [Your Event Illustration Here]
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Features Section */}
      <Container sx={{ py: 8 }}>
        <Typography
          variant="h4"
          component="h2"
          align="center"
          gutterBottom
          sx={{ fontWeight: "bold" }}
        >
          Key Features
        </Typography>
        <Typography
          variant="subtitle1"
          component="p"
          align="center"
          sx={{ color: "text.secondary", maxWidth: 600, mx: "auto" }}
          gutterBottom
        >
          Everything you need for a successful event, from registration to
          attendance tracking.
        </Typography>

        <Grid container spacing={4} sx={{ mt: 4 }}>
          {/* Card Template */}
          {[
            {
              icon: <Event color="primary" />,
              title: "Build Registration Pages",
              text: "Easily create branded registration pages for your events, collect attendee details, and customize the look and feel.",
            },
            {
              icon: <Email color="primary" />,
              title: "Email Confirmations",
              text: "Send automatic confirmation and reminder emails. Keep your attendees informed and engaged at every step.",
            },
            {
              icon: <QrCode color="primary" />,
              title: "QR Code Check-In",
              text: "Generate unique QR codes for each attendee. Easily scan at the entrance for secure and efficient check-ins.",
            },
            {
              icon: <Timeline color="primary" />,
              title: "Footfall Analytics",
              text: "Monitor real-time attendance, view time-wise or day-wise visitor data, and gain insights to improve future events.",
            },
          ].map((feature, index) => (
            <Grid item xs={12} sm={6} md={3} key={index}>
              <Card
                variant="outlined"
                sx={{
                  height: "100%",
                  borderRadius: 3,
                  boxShadow: "0 2px 8px rgba(0,0,0,0.1)",
                  transition: "transform 0.3s ease, box-shadow 0.3s ease",
                  ":hover": {
                    transform: "translateY(-4px)",
                    boxShadow: "0 4px 16px rgba(0,0,0,0.15)",
                  },
                }}
              >
                <CardContent>
                  <Stack direction="row" spacing={2} alignItems="center" mb={2}>
                    {feature.icon}
                    <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                      {feature.title}
                    </Typography>
                  </Stack>
                  <Typography variant="body2" sx={{ color: "text.secondary" }}>
                    {feature.text}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>

        {/* Call to Action Section */}
        <Box textAlign="center" mt={8}>
          <Typography variant="h5" gutterBottom sx={{ fontWeight: "bold" }}>
            Ready to streamline your event management?
          </Typography>
          <Button
            variant="contained"
            size="large"
            sx={{
              mt: 3,
              background: "linear-gradient(to right, #02475f, #f50057)",
              color: "#fff",
              borderRadius: "8px",
              px: 4,
              py: 1.5,
              fontWeight: "bold",
              textTransform: "none",
              boxShadow: "0 4px 10px rgba(0,0,0,0.2)",
              transition: "background 0.3s ease",
              ":hover": {
                background: "linear-gradient(to right, #023546, #d4004f)",
                boxShadow: "0 6px 14px rgba(0,0,0,0.3)",
              },
              ":active": {
                boxShadow: "0 3px 6px rgba(0,0,0,0.2)",
              },
            }}
          >
            Book a Trial Version
          </Button>
        </Box>
      </Container>

      {/* Footer Section */}
      <Box sx={{ bgcolor: "grey.100", py: 4, mt: 8 }}>
        <Container>
          <Typography
            variant="body2"
            sx={{ color: "text.secondary" }}
            align="center"
          >
            © {new Date().getFullYear()} EventMaster. All rights reserved.
          </Typography>
        </Container>
      </Box>
    </Box>
  );
}
