import axios from "axios";
import React, { useState, useRef, useEffect } from "react";
import { Alert, Box, Typography } from "@mui/material";
import { toastError } from "../../utils/util";
import CustomCircularP from "../../widgets/CustomCircularP";
import { API_ENDPOINT } from "../../utils/constants";
import { printRow } from "./ImtexBadgeDimension";
import { useNavigate, useParams } from "react-router-dom";
import { getOnsiteHeader, isOnsiteActivated } from "./utils";
import useCommonGetInit from "../../hooks/useCommonGetInit";
import MuPb from "../../widgets/MuPb";

function SelfPrinting() {
  const { id } = useParams();
  const navigate = useNavigate();

  // Get data to check module activation, etc.
  const {
    data: Mdata,
    isLoading: loadingM,
    isError,
    error,
  } = useCommonGetInit(`${API_ENDPOINT}user/check-onsite?id=${id}`);

  // Local loading & message states
  const [isLoading, setIsLoading] = useState(false);
  const [printMessage, setPrintMessage] = useState("");

  const inputRef = useRef();

  // Handle "Enter" key press in the input field
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      const scannedValue = event.target.value.trim();
      if (scannedValue) {
        fetchUserDetails(scannedValue);
      }
      // Clear and refocus
      inputRef.current.value = "";
      inputRef.current?.focus();
    }
  };

  // Fetch user details and print
  const fetchUserDetails = async (bid) => {
    try {
      setIsLoading(true);
      setPrintMessage("Please wait... Printing badge.");

      const { data } = await axios.get(
        `${API_ENDPOINT}user/get-scanned-visitor?barcode_id=${bid}`,
        getOnsiteHeader(Mdata.module)
      );

      // Initiate actual printing
      printRow(data);
    } catch (err) {
      toastError(err);
      if (inputRef.current) {
        inputRef.current.value = "";
        inputRef.current.focus();
      }
    } finally {
      // Clear the printing message & loading
      setPrintMessage("");
      setIsLoading(false);
      // Reset & focus the input again
      if (inputRef.current) {
        inputRef.current.value = "";
        inputRef.current.focus();
      }
    }
  };

  // When Mdata becomes available, check if onsite is activated
  // and focus the input if it is.
  useEffect(() => {
    if (Mdata) {
      if (!isOnsiteActivated(Mdata.module)) {
        navigate(-1);
      }

      if (inputRef.current) {
        inputRef.current.value = "";
        inputRef.current.focus();
      }
    }
  }, [Mdata, navigate]);

  // If still loading data from useCommonGetInit
  if (loadingM) {
    return <MuPb />;
  }
  // If there was an error fetching Mdata
  if (isError) {
    return <Alert severity="error">{error?.message}</Alert>;
  }

  return (
    <Box>
      {/* Existing loading spinner (you can also reuse it for printing if you want) */}
      <CustomCircularP show={isLoading} />

      {/* Show a message if we have one (e.g. while printing) */}
      {printMessage && (
        <Typography variant="subtitle1" textAlign="center" color="secondary">
          {printMessage}
        </Typography>
      )}

      <Typography variant="h5" textAlign="center" mt={2} mb={2}>
        The Cursor should be in the Input Field while Scanning.
      </Typography>

      <Box display="table" ml="auto" mr="auto">
        <input
          ref={inputRef}
          id="barcodeInput"
          type="text"
          onKeyDown={handleKeyDown}
          placeholder="Scan QR Code"
          style={{
            padding: "10px",
            fontSize: "16px",
            width: "300px",
            textAlign: "center",
            borderRadius: "4px",
            border: "2px solid #000",
          }}
        />
      </Box>
    </Box>
  );
}

export default SelfPrinting;
