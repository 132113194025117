import React from "react";
import { useParams } from "react-router-dom";
import SecurityScan from "./SecurityScan";
import { Box, Typography } from "@mui/material";

const GatwiseScan = () => {
  const { id, gid } = useParams();
  return (
    <Box>
      <Typography textAlign={"center"} variant="h5">
        {gid}
      </Typography>
      <SecurityScan />
    </Box>
  );
};

export default GatwiseScan;
